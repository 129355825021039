
// ----------------------------------
// ||            Global            ||
// ----------------------------------

html { 
	overflow-x: hidden;
	overflow-y: scroll;
	margin: 0; padding: 0;
	width: 100%;
}

body {
	background-color: $main_bg_color;
	margin: 0; padding: 0;
	-webkit-text-size-adjust: 100%;
	overflow-x: hidden;
	width: 100%;
	padding-right: 0 !important; // Pour contrer les 17px des modales boostrap
	font-family: $korolev !important; // BASE font
	letter-spacing: 0.01rem; // BASE spacing
	color: $color_black; // BASE text color
}

section {
	position: relative;
	width: 100%;
}

section a {
	color: $links_color;
	font-weight: $links_weight;
	text-decoration: $links_decoration;
}
section a:visited {
	color: darken($links_color, 15%);
}

textarea,
input.text,
input[type="text"],
input[type="button"],
input[type="submit"],
div[type="submit"],
*[type="submit"],
button, 
input {
	-webkit-appearance: none !important;
	-moz-appearance: none !important;
	appearance: none !important;
}

input[type="checkbox"] {
	display: inline-block;
	appearance: checkbox !important;
}
input[type="radio"] {
	display: inline-block;
	min-height: 13px;
	width: auto !important;
	appearance: radio !important;
}

button, .btn, .close {
	cursor: pointer;
}

/*select {
	background-image: url("/images/select_arrow.png");
	background-position: right center;
	background-repeat: no-repeat;
}*/

*, *:focus, *:active {
	outline: none !important;
	box-sizing: border-box;
}

img {
	width: 100%;
	height: auto;
}



.full_site {
	position: relative;
	padding: $fullsite_padding;

	.site_content {
		position: relative;
		min-height: 100vh;
	}
}

.hidden {
	display: none;
}

.admin_link {
	position: absolute;
	z-index: 10000;
	background-color: transparent;
	border-top: 26px solid #343a40;
	border-left: 26px solid #343a40;
	border-right: 26px solid transparent;
	border-bottom: 26px solid transparent;
	top: -36px;
	left: -36px;
	animation-name: admin_link_start;
    animation-duration: 0.8s;
	transition: 0.3s;

	i {
		color: white;
		position: absolute;
		top: -20px;
		left: -20px;
	}
}
.admin_link:hover {
	top: 0px;
	left: 0px;
}
@keyframes admin_link_start {
    from {top: 0px;left: 0px;}
    to {top: -36px;left: -36px;}
}

.grecaptcha-badge { display: none; }

.scrollnav {
	position: fixed;
	z-index: 100;
	right: 0;
	bottom: 10%;
	/* transform: translateY(-50%); */
	width: 50px;
	/* background-color: rgba($color_white, 0.2); */

	.scrollnav_link {
		height: 10px;
		width: 10px;
		background-color: rgba($color_black, 0.3);
		margin: 20px 50%;
		border-radius: 1000px;
		margin-left: 50%;
		transform: translateX(-50%);
	}
	.scrollnav_link:hover {
		background-color: rgba($color_black, 0.5);
	}
	.scrollnav_link.active {
		background-color: rgba($color_primary, 1);
	}
}

.popover_content_container { display: none; }
.popover {
	max-width: 350px;

	.popover-header {
		white-space: nowrap;
		color: #c0c0c0;

		.title {
			display: inline-block;
			font-weight: 700;
		}
	}

	.close_pop {
		cursor: pointer;
		display: inline-block;
		margin-left: 10px;
	}
}

.bg_image {
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.altfont {
	font-family: 'nom_police_alternative', sans-serif;
	letter-spacing: 0.05rem;
}

.txt_blanc { color: $color_white !important; }
.txt_noir { color: $color_black !important; }

.container.small { max-width: 800px; }
.container.verysmall { max-width: 500px; }


.square_container:after {
	content: "";
	display: block;
	padding-bottom: 100%;
}
.square_container {
	display: block;
	position: relative;
	
	.square_content {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		img {
			@extend %imgcover;
		}
	}
}

// ----------------- CSS SPECIFIQUE AU SITE -----------------

.site_content {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: calc(20px + 3vw);
	padding-top: 90px;
	height: 100vh;
	width: 100%;
	//background-color: rgba(red, 0.3);
}

.logo {
	position: absolute;
	width: 150px;
	top: 30px;
	left: 30px;

	&.bottomright {
		top: initial;
		left: initial;
		bottom: 30px;
		right: 30px;
		width: 100px;
	}
}

.logo_bolon {
	position: absolute;
	z-index: 10;
	bottom: 50px;
	left: 50%;
	transform: translateX(-50%);
	width: 220px;
	opacity: 1;
	pointer-events: all;
	transition: 0.3s;

	&.hidden {
		opacity: 0;
		pointer-events: none;
	}
}

.navigation_container {
	position: relative;
	width: 100%;
	height: 100%;
	max-height: 90vh;
	max-width: 90vh;
	background-color: $color_white;
	//background-color: red;

	@include media-breakpoint-down($responsive_breakpoint) {
		max-height: 90vw;
		max-width: 90vw;
	}
}

.loading_screen {
	position: fixed;
	z-index: 1000;
	top: 10px;
	left: 50%;
	transform: translateX(-50%);
	background-color: lightgrey;
	padding: 10px 20px;
	border-radius: 1000px;
	opacity: 0;
	pointer-events: none;
	transition: 0.75s;
	

	&.show {
		opacity: 1;
		pointer-events: all;
	}
}

.back {
	cursor: pointer;
	pointer-events: none;
	opacity: 0;
	position: fixed;
	z-index: 3;
	top: 20px;
	left: 20px;
	transition: 0.1s;
	font-size: 1rem;
	font-weight: 300;
	padding: 8px 20px;
	background-color: $color_black;
	color: $color_white;
	line-height: 1;

	i {
		font-size: 0.6rem;
		transform: translateY(-2px);
	}

	&:hover {
		padding: 10px 24px;
	}
}

.top_interface {
	position: absolute;
	z-index: 12;
	top: 20px;
	right: 20px;
	left: auto;
	width: auto;
	padding: 4px;
	opacity: 1;
	pointer-events: all;
	transition: 0.3s;

	&.hide {
		opacity: 0;
		pointer-events: none;
	}

	button#pieces {
		border: none;
		background-color: $color_black;
		color: $color_white;
		font-size: 1rem;
		font-weight: 300;
		line-height: 1;
		padding: 8px 20px;
		transition: 0.1s;

		&:hover {
			padding: 10px 24px;
		}
	}
}

.infos {
	position: absolute;
	display: block;
	text-align: center;
	top: 0;
	@extend %abcenter_x;
	opacity: 1;
	pointer-events: all;
	transition: 0.3s;
	
	.titre {
		font-weight: 300;
		margin-bottom: 30px;
	}

	.soustitre {
		font-size: 1.5rem;
		font-weight: 300;
	}

	&.hidden {
		opacity: 0;
		pointer-events: none;
	}
}

.description {
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: absolute;
	top: 0;
	bottom: 0;
	left: -36px;
	transform: translateX(-100%);
	padding-right: 30px;
	border-right: 1px solid $color_black;
	max-width: 200px;
	text-align: center;
	// Hidden
	pointer-events: none;
	opacity: 0;
	transform: translateX(-80%);
	transition: 0.3s;

	h4 {
		font-size: 1.2rem;
		font-weight: 700;
		margin-bottom: 20px;
	}

	p {
		font-weight: 300;
		line-height: 1.3;
	}

	img.triangle {
		position: absolute !important;
		right: -23px !important;
		top: 50% !important;
		width: 24px !important;
		height: auto;
		bottom: initial;
		left: initial;
	}
}

// .bottomright_interface {
// 	position: absolute;
// 	z-index: 12;
// 	right: 10px;
// 	bottom: 10px;
// 	width: auto;
// 	transition: 0.5s;

// 	&.visible {
// 		pointer-events: all;
// 		opacity: 1;
// 	}
// 	pointer-events: none;
// 	opacity: 0;

// 	.message {
// 		font-size: 1.8rem;
// 		font-weight: 700;
// 		color: #bbbbbb;
// 		line-height: 1.2;
// 		text-align: right;
// 		opacity: 0.75;
// 	}
// }

.message_sol_container {
	pointer-events: none;
	opacity: 0;
	transition: 0.2s;
	position: absolute;
	left: 30px;
	top: 50%;
	transform: translateY(-50%);
	padding: 30px;
	background-color: $color_white;
	font-weight: 700;
	font-size: 1.5rem;
	width: 250px;
	line-height: 1.3;
	text-align: center;

	p {
		margin-bottom: 30px;
		&:last-child { margin: 0; }
	}

	&.visible {
		pointer-events: all;
		opacity: 1;
	}

	.close_message {
		cursor: pointer;
		position: absolute;
		z-index: 10;
		top: -7px;
		right: -7px;
		font-family: Arial, sans-serif;
		font-size: 0.7rem;
		font-weight: 700;
		padding: 2px 5px;
		color: $white;
		background-color: $color_black;
		opacity: 1;
	}
}

.slate {
	position: absolute;
	//display: flex;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 20px;
	border: 1px solid $color_black;
	transition: $transition_time;
	@include media-breakpoint-down($responsive_breakpoint) {
		padding: 0;
	}

	.nom_container {
		position: absolute;
		left: 50%;
		bottom: -12px;
		transform: translate(-50%, 100%);
		z-index: 2;
		opacity: 1;
		width: 100%;
		transition: $transition_time;
		text-align: center;

		.soustitre {
			font-weight: 100;
			font-size: 1.05rem;
			line-height: 1.2;
			@include media-breakpoint-down($responsive_breakpoint) {
				font-size: 0.9rem;
			}
			@include media-breakpoint-down(xs) {
				font-size: 0.65rem;
			}
		}
	}

	.nom {	
		font-size: 1.05rem;
		font-weight: 700;
		line-height: 1;
		margin-bottom: 20px;
		@include media-breakpoint-down($responsive_breakpoint) {
			font-size: 0.9rem;
		}
		@include media-breakpoint-down(xs) {
			font-size: 0.65rem;
		}
	}

	&.has5child.current, &.has6child.current, &.has7child.current, &.has8child.current, &.has9child.current {
		.slate .nom {
			font-size: 0.95rem;
		}
	}
	
	img {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 1;
		transition: 0.3s;
	}
}

.slate.prev {
	pointer-events: none;
	border: 0 solid rgba($color_black, 0);

	> .nom_container, > img, > .description {
		pointer-events: none;
		opacity: 0;
		display: none;
	}
}

.slate.current {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	pointer-events: all;
	border: 0 solid rgba($color_black, 0);
	padding-top: 20px;

	> .nom_container {
		bottom: initial;
		top: 0;
		transform: translate(-50%, -100%);
		width: 100%;
		
		.nom {
			font-size: 1.6rem;
			font-weight: 300;
		}

		.soustitre {
			display: none;
		}
	}

	> .nom {
		@include media-breakpoint-down($responsive_breakpoint) {
			font-size: 1.2rem;
		}
	}

	> .description {
		pointer-events: all;
		opacity: 1;
		transform: translateX(-100%);
	}
	
	> img {
		height: 100%;
		object-fit: cover;
		pointer-events: none;
		opacity: 0;
	}

	> .back {
		pointer-events: all;
		opacity: 1;
	}
}

.slate.next {
	pointer-events: all;
	cursor: pointer;
	position: relative;
	margin: $sim_gutters/2;
	margin-bottom: $sim_gutters/2 + 10px;
	height: 32vh;
	width: 100%;
	padding: 0;
	@include media-breakpoint-down($responsive_breakpoint) {
		margin: $sim_gutters/4;
		margin-bottom: $sim_gutters/4 + 10px;
		height: 32vw;
	}

	img {
		position: relative;
		@extend %imgcover;
	}
	
	.slate {
		display: none;
	}
}

.slate.current {
	> .slate {
		width: calc(50% - #{$sim_gutters});
		@include media-breakpoint-down($responsive_breakpoint) { width: calc(50% - #{$sim_gutters/2}); }
	}
	&.has2child, &.has3child, &.has4child {
		> .slate {
			width: calc(50% - #{$sim_gutters});
			@include media-breakpoint-down($responsive_breakpoint) { width: calc(50% - #{$sim_gutters/2}); }
		}
	}

	&.has5child, &.has6child, &.has7child, &.has8child, &.has9child {
		> .slate {
			width: calc(33.333% - #{$sim_gutters});
			height: 20vh;
			@include media-breakpoint-down($responsive_breakpoint) { 
				width: calc(33.333% - #{$sim_gutters/2}); 
				height: 23vw;
			}
		}
	}
}

// .slate.current.has2child {
// 	> .slate {
// 		width: calc(50% - 20px);
// 	}

// 	> .slate:nth-child(1) {
// 		left: 0;
// 	}

// 	> .slate:nth-child(2) {
// 		left: auto;
// 		right: 0;
// 	}
// }

.slate.hide {
	display: none;
	pointer-events: none;
	opacity: 0;
	transition: 0;
}

.test {
	display: none;
}

.simulator_link {
	padding: 20px;
	border: 1px solid $color_black;

	.noreference {
		pointer-events: none;
		position: absolute;
		z-index: 10;
		left: -5px;
		right: -5px;
		top: -5px;
		bottom: -5px;
		background-color: rgba(red, 0.5);
	}

	.wait_loading {
		position: absolute;
		z-index: 11;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		font-size: 1rem;
		font-weight: 700;
		color: red;
		background-color: rgba($color_white, 0.5);
		opacity: 0;
		transition: 0.4s;
	}

	&:hover.noclick {
		cursor: not-allowed;

		.wait_loading {
			opacity: 1;
		}
	}
}

.simulateur_container {
	position: fixed;
	z-index: 11;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	//padding: calc(20px + 3vw);
	background-color: $color_white;
	transition: 0.3s;
	opacity: 0;
	pointer-events: none;

	iframe {
		height: 100%;
		width: 100%;
		border: none;
	}

	.menu_bas_gauche_container {
		position: absolute;
		z-index: 0;
		bottom: 20px;
		right: 20px;
		min-width: 500px;
		max-width: 600px;
		transition: 0.3s;
		@include media-breakpoint-down($responsive_breakpoint) {
			min-width: initial;
			max-width: calc(100% - 40px);
		}

		.switch_button {
			cursor: pointer;
			position: absolute;
			z-index: 10;
			top: 0;
			right: 20px;
			transform: translateY(-50%);
			height: 30px;
			width: 30px;
			background-color: $black;
			border-radius: 1000px;
			transition: 0.3s;

			i {
				position: absolute;
				@extend %abcenter_xy;
				color: $white;
				transform: translate(-50%, -50%) rotate(180deg);
				transition: 0.1s;
			}
		}

		&.folded {
			transform: translateY(calc(100% + 20px));

			.switch_button {
				transform: translateY(-110%);

				i {
					transform: translate(-50%, -50%) rotate(0);
				}
			}
		}
	}

	#menu {
		height: 100%;
		width: 100%;

		.list-group-item {
			border-radius: 0;
			border: 1px solid $color_black;
			display: flex;
			flex-direction: column;

			.container-content {
				order: 1;
				margin-bottom: 20px;

				.img-wrapper {
					min-height: 135px;
				}
			}

			.container-header {
				order: 2;
				display: flex;
				
				* {
					font-weight: 300;
				}

				.div-rotation {
					order: 1;
					margin-right: 50px;
					transform: translateX(10px);

					.rotation-button {
						border: none;
						color: $white;
						background-color: $black;
						font-weight: 700;
						height: 26px;
						width: 26px;
					}
				}

				.switch-button-container {
					order: 2;

					.custom-control-input:checked ~ .custom-control-label::before {
						border-color: $color_black;
						background-color: $color_black;
					}
				}
			}
		}

		.sub-container-wrapper, .category-container {
			//min-height: 250px;
			max-height: 100%;
		}
		.category-container {
			min-height: initial;
			//max-height: 200px;
		}
		.img-wrapper {
			height: auto;
			max-height: 180px;
		}

		.custom-control-input, label.custom-control-label, label.custom-control-label::before, label.custom-control-label::after {
			cursor: pointer;
		}

		.category-wrapper {
			background-color: #cccccc;
			line-height: 1.1 !important;

			.category {
				text-transform: lowercase;
				//text-transform: capitalize;
				font-weight: 700;
				line-height: 1.1 !important;

				&::first-letter {
					text-transform: capitalize;
				}
			}
		}

		.img-content {
			flex: 20%;

			img {
				max-height: 70px;
				max-width: 70px;
			}

			.text-img {
				text-transform: uppercase;
			}
		}
		
	}
}
.simulateur_container.open {
	opacity: 1;
	pointer-events: all;

	.back {
		opacity: 1;
		pointer-events: all;
	}
}

.slate[data-class="noclic"] {
	pointer-events: none;
}

/*.bouton_type_1 {
	cursor: pointer;
	padding-bottom: 4px;
	font-size: 1.3rem;
	line-height: 1rem;
	font-weight: 700;
	color: $color_black !important;
	border-bottom: 1px solid $color_black;
	text-decoration: none;
}
.bouton_type_1:hover {
	text-decoration: none;
	border-bottom: 2px solid $color_black;
}
.bouton_type_2 {
	cursor: pointer;
	background-color: rgba($color_bleu, 0);
	border: 1px solid $color_bleu;
	border-radius: 0;
	font-size: 1rem;
	text-transform: uppercase;
	font-weight: 700;
}
.bouton_type_2:hover {
	text-decoration: none;
	border: 1px solid $color_bleu;
	background-color: rgba($color_bleu, 0.1);
}*/


// POP-UP PIECES
/* Popup pièces */
.room_choice_modal {
	background-color: rgba($color_black, 0.5);

	.modal-dialog {

	}
	.modal-content {
		border-radius: 0;
		border: 1px solid $color_black;
		//background-color: red;

		.modal-subcontent {
			display: flex;
			flex-wrap: wrap;
			padding: 10px;
			padding-top: 0;
			padding-bottom: 30px;

			.modal-piece {
				cursor: pointer;
				margin: 20px 15px;
				width: calc(50% - 30px);

				img {
					@extend %imgcover;
					border: 1px solid lightgrey;
				}
				img:hover {
					opacity: 0.9;
				}
			}
		}
		
	}
}

// #configurator {
// 	.button-list {
// 		justify-content: flex-start;

// 		.button-interface {
// 			transform: translateX(50px)
// 		}
// 	}
	
// }


@include media-breakpoint-down($responsive_breakpoint) {
	
}

// ----------------------------------------------------------
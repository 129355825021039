
// ----------------------------------
// ||            Navbar            ||
// ----------------------------------

// ----------------- CSS SPECIFIQUE AU SITE -----------------

.navbar {



}

// ----------------------------------------------------------

// NAVBAR - GÉNÉRAL
.site_content {
	@if $navbar_position == fixed_top {
		padding-top: $navbar_fixed_decalage;
	}
	@if $navbar_position == fixed_bottom {
		padding-top: $navbar_fixed_decalage;
	}
}

.navbar {
	z-index: 1000;
	width: $navbar_width;
	background-color: $navbar_bgcolor;
	@if $navbar_bggradient != 0 {
		background: $navbar_bggradient;
	}
	box-shadow: 0px 0px $navbar_shadow_height rgba(0, 0, 0, $navbar_shadow_opacity);
	@if $navbar_position == fixed_top {
		position: fixed;
		top: 0;
	}
	@if $navbar_position == fixed_bottom {
		position: fixed;
		bottom: 0;
	}

	.container {
		@if $navbar_contained == 0 { 
			max-width: none !important; 
			padding: 0;
		}

		.etage {
			width: 100%;
		}
	}

	.puller {
		@if $navbar_toggler_position == 'left' {
			display: none;
		}
	}

	.navbar_collapsed_header { display: none; }
}


// NAVBAR - BRAND
.navbar-brand {
	height: $navbar_brand_height;

	@if $navbar_brand_vertical_position == top { align-self: flex-start; }
	@if $navbar_brand_vertical_position == bottom { align-self: flex-end; }

	img {
		display: block;
		height: 100%;
		width: auto;
	}
}
.logo_in_collapse { display: none; }
.navbar-brand-floating { display: none; }


// NAVBAR - LIENS
@if $navbar_links_align == right {
	.nav-item:first-child {
		margin-left: auto;
	}
}
@if $navbar_links_align == center {
	.nav-item:first-child, .nav-item.icon:first-child {
		margin-left: auto !important;
	}
	.nav-item:last-child, .nav-item.icon:last-child {
		margin-right: auto !important;
	}
}
.nav-item {
	position: relative;
	margin: $navbar_links_margin;

	.nav-link {
		position: relative;
		top: 50%;
		transform: translateY(-50%);
		font-size: $navbar_links_fontsize;
		font-weight: $navbar_links_fontweight;
		color: $navbar_links_color;
		background-color: $navbar_links_bgcolor;
		border-radius: $navbar_links_border_radius;
		padding: $navbar_links_padding-y $navbar_links_padding-x !important;
		transition: $navbar_links_animtime;
		@if $navbar_links_forceuppercase == 1 {
			text-transform: uppercase;
		}
		@if $navbar_dropdown_animation_type == slide_fixed_title {
			z-index: 10000 !important;
		}
	}

	@if $navbar_underline_use == 1 {
		.nav-underline {
			position: absolute;
			bottom: -$navbar_underline_decalage;
			background-color: $navbar_underline_color;
			transition: $navbar_underline_animtime;

			@if $navbar_underline_popdirection == 'left' {
				height: $navbar_underline_height;
				width: 0;
				left: 0;
			}

			@if $navbar_underline_popdirection == 'right' {
				height: $navbar_underline_height;
				width: 0;
				right: 0;
			}

			@if $navbar_underline_popdirection == 'center' {
				height: $navbar_underline_height;
				width: 0;
				left: 50%;
				transform: translateX(-50%);
			}

			@if $navbar_underline_popdirection == 'bottom' {
				height: 0;
				width: $navbar_underline_width;
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}
	@else { .nav-underline { display: none; } }

}


// NAVBAR - LIENS HOVER
.nav-item:hover, .nav-item:active, .nav-item.active, .nav-item.show {

	.nav-link {
		color: $navbar_links_color_hover;
		background-color: $navbar_links_bgcolor_hover;
		border-radius: $navbar_links_border_radius_hover;
	}

	@if $navbar_underline_use == 1 {
		.nav-underline {

			@if $navbar_underline_popdirection == 'left' {
				width: $navbar_underline_width;
			}

			@if $navbar_underline_popdirection == 'right' {
				width: $navbar_underline_width;
			}

			@if $navbar_underline_popdirection == 'center' {
				width: $navbar_underline_width;
			}

			@if $navbar_underline_popdirection == 'bottom' {
				height: $navbar_underline_height;
			}

		}
	}

}

// NAVBAR - ICONES
.nav-item.icon {
	margin: 0 $navbar_icon_margin !important;


	.nav-link {
		position: relative;
		top: 50%;
		transform: translateY(-50%);
	}
	.nav-link:hover {
		background-color: transparent !important;
	}

	.nav_icon_container {
		border: $navbar_iconcontainer_border;
		border-radius: $navbar_iconcontainer_borderradius;
		padding: $navbar_iconcontainer_padding;
		background-color: $navbar_iconcontainer_bgcolor;
		height: $navbar_icon_border_size;
		width: $navbar_icon_border_size;
		transition: $navbar_iconcontainer_animtime;

		i {
			@extend %abcenter_xy;
			color: $navbar_links_color;
			transition: $navbar_links_animtime;
			font-size: $navbar_icon_size;
		}
	}
}

.nav-item:hover {

	.nav_icon_container {
		border: $navbar_iconcontainer_border_hover;
		border-radius: $navbar_iconcontainer_borderradius_hover;
		background-color: $navbar_iconcontainer_bgcolor_hover;
	}

	i {
		color: $navbar_links_color_hover;
	}
}


// NAVBAR - DROPDOWNS
.nav-item .dropdown-menu {
	padding: $navbar_dropdown_padding-y $navbar_dropdown_padding-x;
	background-color: $navbar_dropdown_bgcolor;
	border-radius: $navbar_dropdown_borderradius !important;
	border: $navbar_dropdown_bordersize solid $navbar_dropdown_bordercolor;
	box-shadow: $navbar_dropdown_shadow !important;
	@if $navbar_dropdown_position == below {
		top: 100%;
	}
	@if $navbar_dropdown_position == above {
		top: 0;
	}

	@if $navbar_dropdown_animation_type == slide_fixed_title {
		top: 0;
		left: calc(-#{$navbar_dropdown_links_padding-x} - #{$navbar_dropdown_padding-x} + #{$navbar_links_padding-x});
	}

	a { 
		color: $navbar_dropdown_link_color;
		@if $navbar_dropdown_links_forceuppercase == 1 {
			text-transform: uppercase;
		} @else {
			text-transform: none;
		}
		font-size: $navbar_links_fontsize;
		padding: $navbar_dropdown_links_padding-y $navbar_dropdown_links_padding-x;
		text-align: $navbar_dropdown_links_align;
	}

	a:hover {
		color: $navbar_dropdown_link_colorhover;
		background-color: $navbar_dropdown_links_bghover;
	}

	@if $navbar_dropdown_animation_type != none {
		transition: $navbar_dropdown_animation_time;
		display: block !important;
	}

	@if $navbar_dropdown_animation_type == unfold {
		overflow: hidden;
		padding: 0;
		max-height: 0;
	}

	@if $navbar_dropdown_animation_type == opacify {
		opacity: 0;
		pointer-events: none;
	}

	@if $navbar_dropdown_animation_type == slide OR $navbar_dropdown_animation_type == slide_fixed_title {
		opacity: 0;
		pointer-events: none;
		transform: translateY(50px);
	}

	@if $navbar_dropdown_animation_type == slide_fixed_title {
		padding-top: 60px;
	}
}

.nav-item .dropdown-menu.show {
	@if $navbar_dropdown_animation_type == none {
		display: block;
	}

	@if $navbar_dropdown_animation_type == unfold {
		padding: $navbar_dropdown_padding-y $navbar_dropdown_padding-x;
		max-height: 50vh;
	}

	@if $navbar_dropdown_animation_type == opacify {
		opacity: 1;
		pointer-events: auto;
	}

	@if $navbar_dropdown_animation_type == slide OR $navbar_dropdown_animation_type == slide_fixed_title {
		opacity: 1;
		pointer-events: auto;
		transform: translateY(-10px);
	}
}




// NAVBAR - TOGGLER

// Gestion angle si toggler trop allongé
$togglerbar_angle: 45deg;
/* $navbar_toggler_ratio: $navbar_togglericon_height / $navbar_togglericon_width;
@if $navbar_toggler_ratio < 0.5 { $togglerbar_angle: 25deg; } */

.navbar-toggler {
	z-index: 100;
	cursor: pointer;
	background-color: $navbar_toggler_bgcolor;
	font-size: $navbar_togglericon_size;
	border: $navbar_toggler_border;
	border-radius: $navbar_toggler_borderradius;

	.navbar-toggler-icon {
		position: relative;
		width: $navbar_togglericon_width;

		.toggler-bar {
			position: absolute;
			width: 100%;
			left: 0;
			height: $navbar_togglerbar_height;
			background-color: $navbar_togglerbar_color;
			transform-origin: center center;
			transform: translateY(-50%);
			transition: $navbar_toggler_animtime;
		}
		// Barres quand le menu est ouvert
		.top-bar {
			top: 50%;
			transform: translateY(-50%) rotate($togglerbar_angle);
		}
		.middle-bar {
			top: 50%;
			opacity: 0;
			transition: $navbar_toggler_animtime;
		}
		.bottom-bar {
			top: 50%;
			transform: translateY(-50%) rotate(-$togglerbar_angle);
		}
	}
}

@if $navbar_toggler_animation == 1 {
	.navbar-toggler.collapsed {
		transform: translateY(0%);
		.navbar-toggler-icon {
			// Barres quand le menu est fermé
			.top-bar {
				top: 0;
				transform: translateY(0%) rotate(0deg);
			}
			.middle-bar {
				opacity: 1;
				transition: $navbar_toggler_animtime;
			}
			.bottom-bar {
				top: 100%;
				transform: translateY(-100%) rotate(0deg);
			}
		}
	}	
}


// NAVBAR - COMPORTEMENT RESPONSIVE

@include media-breakpoint-down($navbar_collapse_breakpoint) {
	
	.navbar {
		padding: 0;

		.navbar-brand, .navbar-toggler {
			z-index: 10;
		}

		.logo_in_collapse { display: block; }
		.puller { display: none; }

		/* .nav-item:first-child {
			margin: $navbar_links_margin;
		}
		.nav-item:last-child {
			margin: $navbar_links_margin;
		} */

		@if $navbar_collapse_keep_underline == 0 {
			.nav-underline {
				display: none;
			}
		}

		@if $navbar_collapse_direction == horizontal {
		
			.navbar-collapse { 
				height: $navbar_collapse_height !important;
				width: $navbar_collapse_width !important;
				transform: translateX(100%);

				@if $navbar_collapse_position == left {
					left: 0;
					right: auto; 
				}
				@if $navbar_collapse_position == right {
					left: auto;
					right: 0; 
				}
			}

			.navbar-collapse.show {
				transform: translateX(0%) !important;
				transition: 0.5s;
			}

		}

		.navbar-collapse {
			order: 3;
			@if $navbar_collapse_bgcolor != keep { background-color: $navbar_collapse_bgcolor; }
			@else { background-color: $navbar_bgcolor; }
			width: $navbar_collapse_width;
			transition: 0.5s;
			transition-delay: 0;

			/* .filler {
				position: absolute;
				pointer-events: none;
				height: 100vh;
				width: 100%;
			} */

			@if $navbar_collapse_position == top {
				top: 0;
				bottom: auto; 
			}
			@if $navbar_collapse_position == bottom {
				top: auto;
				bottom: 0; 
			}

			.container {
				display: flex;
				height: $navbar_collapse_height;
			
				/* width: 100%; */
				@if $navbar_collapse_content_horizontal_align == left { justify-content: flex-start; }
				@if $navbar_collapse_content_horizontal_align == center { justify-content: center; }
				@if $navbar_collapse_content_horizontal_align == right { justify-content: flex-end; }
				@if $navbar_collapse_content_vertical_align == center { align-items: center; }

				.col {
					padding: $navbar_collapse_col_padding;
					flex-grow: initial;
					@if $navbar_collapse_content_vertical_align == top { align-self: flex-start; }
					@if $navbar_collapse_content_vertical_align == center { align-self: center; }
					@if $navbar_collapse_content_vertical_align == bottom { align-self: flex-end; }

					.etage {
						padding: $navbar_collapse_etage_padding;
						width: 100%;
						flex-grow: initial;

						img {
							width: 100%;
							height: auto;
						}
					}
				}

				.navbar-nav {
					display: inline-block;
					/* @if $navbar_collapse_vertical_align == top { align-self: flex-start; }
					@if $navbar_collapse_vertical_align == bottom { align-self: flex-end; } */
					text-align: $navbar_collapse_links_centering;

					a {
						font-size: $navbar_collapse_links_fontsize !important;
						font-weight: $navbar_collapse_links_fontweight;
					}

					.nav-item.icon {
						display: inline-block;
					}


					.nav-link {
						@if $navbar_collapse_links_color != keep { color: $navbar_collapse_links_color; }
						transform: none;
					}
					.nav-link .nav_icon_container {
						@if $navbar_collapse_links_color != keep { border-color: $navbar_collapse_links_color; }
						i {
							@if $navbar_collapse_links_color != keep { color: $navbar_collapse_links_color; }
						}
					}
					
				}
			}
		}	
	}


	// Order toggler et logo en fonction de la position choisie
	@if $navbar_toggler_position == 'right' {
		.navbar-brand { order: 1; }
		.navbar-toggler { order: 2; }
	}

	// Passage en mode nobar
	@if $navbar_collapse_nobar == 1 {
		.navbar-brand { display: none; }

		.background_nobar {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			height: $navbar_background_nobar_height;
			background-color: $navbar_background_nobar_color;
		}

		.navbar {
			padding: 0;
			background-color: transparent;
		}

		.navbar-toggler {
			position: fixed;
			top: $navbar_toggler_distance;
			@if $navbar_toggler_position == left { left: $navbar_toggler_distance; }
			@if $navbar_toggler_position == right { right: $navbar_toggler_distance; }

		}

		.navbar-collapse {
			position: fixed;
			width: 100%;
			top: 0;
			left: 0;
			right: 0;
		}

		.navbar-brand-floating {
			@if $navbar_collapse_floating_fixed == 0 { position: absolute; }
			@if $navbar_collapse_floating_fixed == 1 { position: fixed; }
			z-index: 1001;
			display: block;
			width: $navbar_collapse_floating_width;
			top: $navbar_collapse_floating_distT;
			@if $navbar_toggler_position == 'left' { right: $navbar_collapse_floating_distL; }
			@if $navbar_toggler_position == 'right' { left: $navbar_collapse_floating_distL; }
			

			img {
				width: 100%;
			}
		}
	}

	// Gestion dropdown en responsive
	.nav-item .dropdown-menu {
		position: relative;
		transition: none;
		display: block;
		opacity: 1;
		transform: none;
		padding-top: 0;
		background: none;
		background-color: none;
		@if $navbar_collapse_links_centering == left { padding-left: $navbar_collapse_dropdown_decalage; }
		@if $navbar_collapse_links_centering == right { padding-right: $navbar_collapse_dropdown_decalage; }
	}

}







#menu {
    body {
    overflow: scroll;
    margin: 0;
    background: #F2F1F6;
  }
  #viewer, #menu {
    position: relative;
    left: calc((100vw - 60vw ) / 2);
    border-radius: 3px;
    box-shadow: 0px 0px 50px rgba(0,0,0,0.25);
    width: 60vw;
  }
  #viewer {
    height: 60vh;
    border: none;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  #pieces {
    position: absolute;
    top: 5px;
    left: calc((100vw - 100px)/2);
    margin-top: 5vh;
    padding: 5px;
    z-index: 10000;
    width: 100px;
    border: 1px solid lightgrey;
    border-radius: 20px;
    text-align: center;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  /* === MENU === */
  .row {
    margin-left: 2px !important;
  }
  .list-group-item {
	  font-size: 18px;
	  font-weight: 800;
    margin-bottom: 5px;
    width: 100%;
  }

/* buttons */
.switch-button-container {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

input[type=checkbox]{
  z-index: 10000;
}

.div-rotation {
  display: flex;
  justify-content: center;
  margin-left: -10px;
}
.rotation-button {
  width: 30px;
  height: 30px;
  font-weight: 700;
  border-radius: 50%;
  background-color: white;
  border: 2px solid #007bff;
  transition: all 0.5s ease;
  box-shadow:2px 2px 8px #aaa;
  line-height: 15px;
}
.rotation-button::-moz-focus-inner {
  border: 0;
} 
.rotation-button:hover, .rotation-button:active {
  background-color: #007bff;
  color: white;
  outline: none;
}
.rotation-button:active{
  width: 27px;
  height: 27px;
}
.ext-label-container {
  display: flex;
}
.label-switch, .label-rotation {
  font-size: 0.8em;
  font-weight: 600;
}

.label-switch {
  margin-right: 60px;
}

.label-rotation {
  margin-left: 50px;
  margin-right: 50px;
}

/* submenu content */
.sub-container-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-height: 150px;
    margin-top: 10px;
}
.sub-container-wrapper.other {
  flex-direction: row;
  overflow-y: scroll;
  height: 150px;
}
.sub-container-wrapper.cat-clicked {
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.sub-container-header {
  display: flex;
  margin-bottom: 5px;
}
.sub-container-header, .category-wrapper {
  cursor: pointer;
}
.sub-container-header, .category-container {
  transition: all 0.5s ease;
}
.sub-container-content, .category-container {
  display: block;
}
.sub-container-content {
  width: 100%;
}
.sub-container-header, .category-wrapper {
    border: 1px solid #e0e0e0;
    padding: 5px 0;
}
.category-container, .category-wrapper {
    width: 100%;
}
.category-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  overflow-Y: scroll;
  max-height: 100px;
}

.category-wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex: 0 22.5%;
  height: 70px;
  line-height: 70px;
  text-align: center;
  border: 1px solid rgba(224, 224, 224, 0.5);
  background-color: lightblue;
  margin: 2px;
}
.category {
  cursor: pointer;
  text-transform: lowercase;
  font-size: 0.6em;
}
i {
  font-size: 1.5em;
  margin: 0 15px;
}
.img-wrapper {
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;
    height: 100px;
  /* firefox browser */
   /*scrollbar-color: rgba(33, 37, 41, 0.6) white;
   scrollbar-width: thin;*/
}
  /* chrome browser */
.img-wrapper::-webkit-scrollbar.cat-clicked {
  width: 6px;
  background-color: white;
}
.img-wrapper::-webkit-scrollbar-thumb.cat-clicked {
  background-color: rgba(33, 37, 41, 0.6);
}

.img-content {
  flex: 12.5%;
  text-align: left;
  width: 100%;
}
.text-img, img {
    cursor: pointer;
}
.text-img {
    font-size: 0.5em;
    margin: auto;
    padding: 0 2px;
    text-transform: lowercase;
}
  img {
    max-width: 40px;
    max-height: 40px;
    margin: 5px 5px auto auto;
    border-radius: 5%;
  }
  img:hover {
    padding: 1px;
  }
.active {
    border: 1px solid rgba(1, 1, 1, 0.8);
  }

.container-content {
  margin: 0;
  padding: 0;
  font-size:1em;
	font-weight: 400;
}

.text-selected {
  font-weight: 400;
  font-size: 0.7em;
  margin-top: 5px;
  margin-bottom: 0;
}

/* Separator */
.horizontal {
  padding: 0;
  border-top: 1px solid #dcdcdc;
}

.hide {
  display: none;
}

/* Popup pièces */

.modal-title {
  margin-left: auto;
}

.modal-subcontent {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.modal-piece {
  flex: 50%;
  text-align: center;
  margin-top: 10px;
}
.txt-piece {
  text-align: left;
  font-weight: 600;
  margin-left: 20px;
}
img.img-piece {
  max-width: 200px;
  max-height: 110px;
  width: 100%;
  height: 100%;
}

.appear {
  opacity: 1!important;
  visibility: visible!important;
}


@media screen and (max-width: 637px){
  .category-wrapper {
    max-height: 40px;
    line-height: 40px;
  } 
  .category {
    width: 70px;
  }
}


}